import React from 'react';
// import Footer from './Footer';
// import Header from './Header';
import GhostHeader from './GhostHeader';

const Layout = ({ children }) => {
  return (
    <>
      {/* <Header /> */}
      <GhostHeader />
      <main className="text-gray-900" style={{overflow: 'hidden'}}>{children}</main>
      {/* <Footer /> */}
    </>
  );
};

export default Layout;
