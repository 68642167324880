import React from 'react';
// import logoSrc from './logo.png';
import logoSrc from '../../images/lingoreaders-logo-with-cat-white-transparent-small.png'

const Header = () => (
  <header className="w-full shadowHeader">
    <div className="logo container flex flex-col sm:flex-row justify-center items-center mx-auto py-4 px-8">
      <img style={{width: '200px'}}src={logoSrc}/>
    </div>
  </header>
);

export default Header;
